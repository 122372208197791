import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '../Icons';
import { Wrapper, LinkBack, Title } from './TopNavigation.style';

/**
 * TopNavigation component
 */
const TopNavigation = ({ title, to, colorKind }) => (
  <Wrapper>
    <LinkBack to={to} colorKind={colorKind}>
      <ArrowBack size={12} />
      <strong>Danijel Grabež</strong>
    </LinkBack>{' '}
    {title ? (
      <Title element="span" colorKind={colorKind === 'light' ? 'lightTransparent' : 'darkTransparent'}>
        {title}
      </Title>
    ) : null}
  </Wrapper>
);

TopNavigation.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  colorKind: PropTypes.oneOf(['light', 'dark']),
};

TopNavigation.defaultProps = {
  to: '/',
  title: null,
  colorKind: 'dark',
};

export default TopNavigation;
