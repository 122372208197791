import React from 'react';
import PropTypes from 'prop-types';
import { Article as ArticleStyles } from './Article.style';

/**
 * Article component
 */
const Article = ({ children, span, variation, id, ...props }) => (
  <ArticleStyles span={span} variation={variation} id={id} {...props}>
    {children}
  </ArticleStyles>
);

Article.propTypes = {
  children: PropTypes.node.isRequired,
  span: PropTypes.oneOf(['default', 'container']),
  variation: PropTypes.oneOf(['default', 'b', 'bt', '1/3', '1/2']),
  id: PropTypes.string,
};

Article.defaultProps = {
  id: null,
};

Article.defaultProps = {
  /**
   * If set at container, it will span whole grid container
   */
  span: 'default',
  /**
   * Variation explanation:
   * note: all variations have responsive margin-bottom
   * default — responsive margin bottom only
   * b — border top/bottom and responsive padding top/bottom
   * bt - border top and responsive padding top/bottom
   * 1/3 — 2 columns (1/3 + 2/3) which contain responsive padding top/bottom and border-top, while border bottom spans both columns
   * 1/2 - 2 columns (1/2 + 1/2) with responsive padding top/bottom
   */
  variation: 'default',
};

export default Article;
