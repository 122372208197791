import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TallOuterContainer, StickyInnerContainer, HorizontalTranslateContainer } from './HorizontalScroller.style';
import useWindowSize from '../../utils/useWindowSize';
import useMountEffect from '../../utils/useMountEffect';

/**
 * HorizontalScroller component
 */
const HorizontalScroller = ({ children, whiteSpaceSize }) => {
  const [dynamicHeight, setDynamicHeight] = useState(null);
  const [translateX, setTranslateX] = useState(0);
  const containerRef = useRef(null);
  const objectRef = useRef(null);
  const size = useWindowSize();

  const scrollHandler = () => {
    const offsetTop = -containerRef.current.offsetTop;

    setTranslateX(offsetTop);
  };

  const handleDynamicHeight = ref => {
    const objectWidth = ref.current.scrollWidth;
    // magic number - probably ~ padding-left of card container
    // const magicNumber = (vw - 960) / 2 + 960 * 0.3333 + 30;
    const magicNumber = size.width * 0.6;

    const dynamicHeight = objectWidth - size.width + size.height + (whiteSpaceSize > 0 ? whiteSpaceSize : magicNumber);

    setDynamicHeight(dynamicHeight);
  };

  useMountEffect(() => {
    handleDynamicHeight(objectRef);
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  return (
    <TallOuterContainer dynamicHeight={dynamicHeight}>
      <StickyInnerContainer ref={containerRef}>
        <HorizontalTranslateContainer translateX={translateX} ref={objectRef}>
          {children}
        </HorizontalTranslateContainer>
      </StickyInnerContainer>
    </TallOuterContainer>
  );
};

HorizontalScroller.propTypes = {
  children: PropTypes.node.isRequired,
  whiteSpaceSize: PropTypes.number,
};

HorizontalScroller.defaultProps = {
  whiteSpaceSize: 0,
};

export default HorizontalScroller;
