import React from 'react';
import PropTypes from 'prop-types';
import { ListItemStyles } from './List.style';

/**
 * ListItem component
 */
const ListItem = ({ children, ...props }) => <ListItemStyles {...props}>{children}</ListItemStyles>;

ListItem.propTypes = {
  children: PropTypes.node,
};

ListItem.defaultProps = {
  children: null,
};

export default ListItem;
