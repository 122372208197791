import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const articleStyles = ({
  span,
  theme: {
    grid: { breakpoint },
  },
}) => css`
  grid-column: 2;
  position: relative;
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${breakpoint.fromTablet} {
    grid-column: ${span === 'container' ? '1 / -1' : 2};
  }
`;

export const variationStyles = ({
  variation,
  theme: {
    color,
    grid: { breakpoint },
  },
}) => {
  switch (variation) {
    case 'bt':
      return css`
        border-top: 2px solid ${color.grey900Transparent15};
        padding: 30px 0 0;
        ${breakpoint.fromTablet} {
          padding: 40px 0 0;
        }
      `;
    case 'b':
      return css`
        border-top: 2px solid ${color.grey900Transparent15};
        border-bottom: 2px solid ${color.grey900Transparent15};
        padding: 30px 0;
        ${breakpoint.fromTablet} {
          padding: 40px 0;
        }
      `;
    case '1/3':
      return css`
        ${breakpoint.fromPhoneMedium} {
          display: grid;
          grid-gap: 0 30px;
          grid-template-columns: 1fr 2fr;
        }

        .articleColumn {
          padding: 30px 0;
          border-top: 2px solid ${color.grey900Transparent15};

          &:not(:first-of-type) {
            border-bottom: 2px solid ${color.grey900Transparent15};
          }
          ${breakpoint.fromPhoneMedium} {
            border-bottom: none;
            &:last-of-type {
              border-bottom: none;
            }
            &:first-of-type {
              &:before {
                content: '';
                position: absolute;
                height: 2px;
                left: 0;
                right: 0;
                bottom: 0;
                background: ${color.grey900Transparent15};
              }
            }
          }
          ${breakpoint.fromTablet} {
            padding: 40px 0;
            &:first-of-type {
              &:before {
                bottom: 0;
              }
            }
          }
        }
      `;
    case '1/2':
      return css`
        ${breakpoint.fromPhoneMedium} {
          display: grid;
          grid-gap: 0 30px;
          grid-template-columns: 1fr 1fr;
        }
      `;
    case 'default':
      break;
  }
};

/**
 * Component style declarations
 */
export const Article = styled('article')(articleStyles, variationStyles);
const ArticleColumnStyles = styled('div')();

export const ArticleColumn = ({ ...props }) => <ArticleColumnStyles className="articleColumn" {...props} />;
