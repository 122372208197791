import React from 'react';
import PropTypes from 'prop-types';
import { ListStyles } from './List.style';

/**
 * List component
 */
const List = ({ listKind, children }) => <ListStyles listKind={listKind}>{children}</ListStyles>;

List.propTypes = {
  children: PropTypes.node,
  listKind: PropTypes.oneOf(['default', 'unstyled', 'emDash']),
};

List.defaultProps = {
  children: null,
  listKind: 'default',
};

export default List;
