import React from 'react';
import PropTypes from 'prop-types';
import TopNavigation from '../TopNavigation';
import Container from '../Container';
import { Wrapper } from './Intro.style';

/**
 * Intro component
 */
const Intro = ({ children, pageTitle, colorScheme, ...props }) => (
  <Wrapper {...props}>
    <Container>
      {pageTitle ? <TopNavigation title={pageTitle} colorScheme={colorScheme} /> : null}
      {children}
    </Container>
  </Wrapper>
);

Intro.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  colorScheme: PropTypes.oneOf(['light', 'dark']),
};

Intro.defaultProps = {
  pageTitle: null,
  colorScheme: 'dark',
};

export default Intro;
