import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HorizontalSection, ContentContainer, HorizontalSectionFallback } from './StorytellingSection.style';
import HorizontalScroller from '../HorizontalScroller';
import useWindowSize from '../../utils/useWindowSize';

/**
 * StorytellingSection component
 */
const StorytellingSection = ({ offsetKind, whiteSpaceSize, children, ...props }) => {
  const size = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(null);
  const [screenWidth, setWidth] = useState(size.width);

  useEffect(() => {
    setWidth(size.width);

    if (size.width > 730 && size.height > 600) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [size, screenWidth, offsetKind]);

  if (!isDesktop) {
    return <HorizontalSectionFallback {...props}>{children}</HorizontalSectionFallback>;
  } else {
    return (
      <HorizontalSection {...props}>
        <HorizontalScroller whiteSpaceSize={size.width * whiteSpaceSize}>
          <ContentContainer offsetKind={offsetKind}>{children}</ContentContainer>
        </HorizontalScroller>
      </HorizontalSection>
    );
  }
};

StorytellingSection.propTypes = {
  offsetKind: PropTypes.oneOf(['container', 'article']),
  whiteSpaceSize: PropTypes.number,
};

StorytellingSection.defaultProps = {
  offsetKind: 'article',
  whiteSpaceSize: 0.6,
};

export default StorytellingSection;
