/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import ArrowRegularWeight from './ArrowRegularWeight';

const arrowStyles = css`
  transform: rotate(-45deg);
  transition: 0.15s transform ease-in-out;

  a:hover & {
    transform: rotate(-45deg) translateX(2px);
  }
`;

/**
 * ArrowExternal icon component
 */
const ArrowExternal = ({ size }) => <ArrowRegularWeight size={size} css={arrowStyles} />;

ArrowExternal.propTypes = {
  size: PropTypes.number,
};

ArrowExternal.defaultProps = {
  size: 15,
};

export default ArrowExternal;
