import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from '../../utils/utils';

const tooltipStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.fromTablet} {
    position: relative;
    cursor: pointer;
    &[data-tooltip] {
      position: relative;
      display: inline-flex;
      justify-content: center;
      &:after {
        font-size: ${rem(12)};
        content: attr(data-tooltip);
        position: absolute;
        margin: 0 auto;
        bottom: 100%;
        right: 0;
        width: 76px;
        z-index: 5;
        line-height: 1.2;
        color: ${color.white};
        background: ${color.grey900};
        pointer-events: none;
        padding: 7px;
        border-radius: 3px;
        opacity: 0;
        transition: all 0.15s cubic-bezier(0.73, 0.01, 0, 1) 0.1s;
        transform: translateY(15px);
      }
      &:hover,
      &:focus {
        &:after {
          opacity: 1;
          transform: translateY(0) scale(1);
        }
      }
    }
  }
`;

/**
 * Component style declarations
 */
export const TooltipStyles = styled('span')(tooltipStyles);
