import React from 'react';
import PropTypes from 'prop-types';
import { TooltipStyles } from './Tooltip.style';

/**
 * Tooltip component
 */
const Tooltip = ({ position = 'top', content, ...props }) => (
  <TooltipStyles position={position} data-tooltip={content} {...props} />
);

Tooltip.propTypes = {
  position: PropTypes.oneOf(['top']),
  content: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'top',
  content: null,
};

export default Tooltip;
