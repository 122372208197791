import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { ButtonWrapper, Circle } from './Button.style';
import { Arrow } from '../Icons';

/**
 * Button component
 */
const ButtonComponent = ({ onClick, title, isExternal }) => (
  <ButtonWrapper onClick={onClick}>
    <Circle isExternal={isExternal}>
      <Arrow />
    </Circle>{' '}
    {title}
  </ButtonWrapper>
);

const Button = ({ to, onClick, title }) => {
  const isMailLink = RegExp(/mailto:/).test(to);
  const isTelLink = RegExp(/tel:/).test(to);
  const isExternalLink = RegExp(/^https?:\/\//).test(to);
  const externalLink = isMailLink || isTelLink || isExternalLink;

  if (!to) {
    return <ButtonComponent onClick={onClick} title={title} />;
  } else if (externalLink) {
    return (
      <a href={to} rel="noopener noreferrer" target="_blank">
        <ButtonComponent onClick={onClick} title={title} isExternal />
      </a>
    );
  }

  return (
    <GatsbyLink to={to}>
      <ButtonComponent onClick={onClick} title={title} />
    </GatsbyLink>
  );
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  isExternal: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  onClick: () => {},
  title: null,
  isExternal: false,
};

Button.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

Button.defaultProps = {
  to: undefined,
  onClick: () => {},
  title: null,
};

export default Button;
