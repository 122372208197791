/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import Arrow from './Arrow';

const arrowStyles = css`
  transform: rotate(180deg);
  transition: 0.15s transform ease-in-out;

  a:hover & {
    transform: rotate(180deg) translateX(2px);
  }
`;

/**
 * ArrowBack icon component
 */
const ArrowBack = ({ size }) => <Arrow size={size} css={arrowStyles} />;

ArrowBack.propTypes = {
  size: PropTypes.number,
};

ArrowBack.defaultProps = {
  size: 15,
};

export default ArrowBack;
