import React from 'react';
import PropTypes from 'prop-types';

/**
 * Arrow icon component
 */
const Arrow = ({ fill, size, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.38636 10.6136L11.6932 5.30682L6.38636 0L5.06818 1.30682L8.10795 4.34659H0V6.26704H8.10795L5.06818 9.30114L6.38636 10.6136Z"
      fill={fill}
    />
  </svg>
);

Arrow.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

Arrow.defaultProps = {
  fill: 'currentColor',
  size: 12,
};

export default Arrow;
