import { css } from '@emotion/core';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from 'gatsby';
import theme from '../../utils/theme';

export const getLinkColorStyles = colorKind => {
  const { font } = theme;

  switch (colorKind) {
    case 'light':
      return `
        color: ${font.color.light};
        &:hover {
          text-decoration: none;
        }
        &:active {
          color: ${font.color.lightTransparent};
        }
      `;
    case 'dark':
      return `
        color: ${font.color.text};
        &:hover {
          text-decoration: none;
        }
        &:active {
          color: ${font.color.darkTransparent};
        }
      `;
    default:
      return `
        color: ${font.color.text};
        &:hover {
          text-decoration: none;
        }
        &:active {
          color: ${font.color.neutral};
        }
      `;
  }
};

export const linkStyles = ({ colorKind, linkKind }) => css`
  line-height: 1.5;
  text-decoration: ${linkKind === 'no-underline' ? 'none' : 'underline'};
  transition: 0.15s all ease-in-out;
  font-weight: inherit;
  ${getLinkColorStyles(colorKind)};
`;

/**
 * Link component
 */
export const LinkStyles = styled('a')(linkStyles);
export const GatsbyLink = styled(Link, {
  shouldForwardProp: isPropValid,
})(linkStyles);
