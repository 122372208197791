import styled from '@emotion/styled';
import { css } from '@emotion/core';

const horizontalSectionStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  position: relative;
  min-height: 100vh;
  ${breakpoint.min730} {
    margin-left: -15px;
    margin-right: -15px;
  }
  ${breakpoint.fromDesktop} {
    /* stylelint-disable */
    margin-left: calc((-100vw + 960px) / 2);
    margin-right: calc((-100vw + 960px) / 2);
    /* stylelint-enable */
  }
`;

const contentContainerStyles = ({
  offsetKind,
  theme: {
    grid: { breakpoint },
  },
}) =>
  css`
    position: relative;
    height: 100%;
    ${breakpoint.min730} {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
    }
    ${breakpoint.fromTablet} {
      padding-left: ${offsetKind === 'article' ? 'calc(20vw + 30px + 15px / 2)' : '15px'};
    }
    ${breakpoint.fromDesktop} {
      padding-left: ${offsetKind === 'article'
        ? 'calc((100vw - 960px) / 2 + 960px * 0.3333 + 30px)'
        : 'calc((100vw - 960px) / 2)'};
    }
  `;

/**
 * This section serves as a fallback in case
 * JavaScript bundle doesn't kick in right away (noticed on fast 3G networks)
 */
const horizontalSectionFallbackStyles = ({
  offsetKind,
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.min730} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    overflow-x: scroll;
    min-height: 100vh;
    margin-left: -15px;
    margin-right: -15px;
    &:last-of-type {
      margin-bottom: 150px;
    }
  }
  ${breakpoint.fromTablet} {
    padding-left: ${offsetKind === 'article' ? 'calc(20vw + 30px + 15px / 2)' : '15px'};
  }
  ${breakpoint.fromDesktop} {
    /* stylelint-disable */
    margin: 0 calc(-50vw + 480px);
    /* stylelint-enable */
    padding-left: ${offsetKind === 'article'
      ? 'calc((100vw - 960px) / 2 + 960px * 0.3333 + 30px)'
      : 'calc((100vw - 960px) / 2)'};
  }
`;

/**
 * Component style declaration
 */
export const HorizontalSection = styled('div')(horizontalSectionStyles);
export const ContentContainer = styled('div')(contentContainerStyles);
export const HorizontalSectionFallback = styled('div')(horizontalSectionFallbackStyles);
