import isPropValid from '@emotion/is-prop-valid';

/**
 * isValidProp
 * utility checker which uses `isPropValid` function
 * function is skipping checking if the prop === 'element', otherwise it
 * runs through `isPropValid`.
 */
export const isValidProp = prop => (prop === 'element' ? true : isPropValid(prop));

export const rem = px => `${px / 16}rem`;
