import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Heading } from '../../components';

const introStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-top: 50px;
  margin-bottom: 20px;
  ${breakpoint.fromTablet} {
    margin-left: calc(20% + 30px);
    margin-top: 80px;
    margin-bottom: 50px;
  }
  ${breakpoint.fromDesktop} {
    margin-left: calc(33.3333% + 30px);
    margin-top: 120px;
    margin-bottom: 70px;
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding: 0;
  margin: 30px 0 0 0;
  ${breakpoint.fromTablet} {
    margin-top: 50px;
    margin-right: -15px;
  }
  ${breakpoint.fromDesktop} {
    margin-top: 70px;
    margin-right: calc((-100vw + 960px) / 2);
  }
`;

const quoteHeadingStyles = css`
  margin-bottom: 50px;
`;

/**
 * Component style declarations
 */
export const IntroStyles = styled('header')(introStyles);
export const ImageWrapper = styled('figure')(imageWrapperStyles);
export const QuoteHeading = styled(Heading)(quoteHeadingStyles);
