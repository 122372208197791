import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading';
import { Section as SectionWrapper } from './Section.style';

/**
 * Section component
 */
const Section = ({ title, sidebar, children, id, ...props }) => (
  <SectionWrapper id={id} {...props}>
    {title ? (
      <Heading element="h2" weight={500}>
        {title}
      </Heading>
    ) : (
      sidebar
    )}
    {children}
  </SectionWrapper>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  sidebar: PropTypes.node,
};

Section.defaultProps = {
  id: null,
  title: null,
  sidebar: null,
};

export default Section;
