import { css } from '@emotion/core';
import { rem } from '../../utils/utils';

export const baseStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
  element,
}) => css`
  font-size: ${rem(font.heading[element].fontSize)};
  font-weight: 700;
  line-height: ${font.heading[element].lineHeight};
  margin-top: 0;
  ${breakpoint.toTablet} {
    font-size: ${rem(font.heading[element].fontSizeSmall)};
  }

  a {
    font-size: ${rem(font.heading[element].fontSize)};
    ${breakpoint.toTablet} {
      font-size: ${rem(font.heading[element].fontSizeSmall)};
    }
  }
`;

export const kindStyles = ({ theme, colorKind }) =>
  colorKind &&
  css`
    color: ${theme.font.color[colorKind]};
  `;

export const sizeStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
  size,
}) =>
  size &&
  css`
    font-size: ${rem(font.heading[size].fontSize)};
    line-height: ${font.heading[size].lineHeight};
    ${breakpoint.toTablet} {
      font-size: ${rem(font.heading[size].fontSizeSmall)};
    }

    a {
      font-size: ${rem(font.heading[size].fontSize)};
      ${breakpoint.toTablet} {
        font-size: ${rem(font.heading[size].fontSizeSmall)};
      }
    }
  `;

export const weightStyles = ({ weight }) =>
  weight &&
  css`
    font-weight: ${weight};
  `;
