import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyLink, LinkStyles } from './Link.style';

/**
 * Link component
 */
const Link = ({ to, linkKind, colorKind, ...props }) => {
  const isMailLink = RegExp(/mailto:/).test(to);
  const isTelLink = RegExp(/tel:/).test(to);
  const isExternalLink = RegExp(/^https?:\/\//).test(to);

  if (!isMailLink && !isExternalLink && !isTelLink) {
    return <GatsbyLink to={to} colorKind={colorKind} linkKind={linkKind} {...props} />;
  } else {
    return (
      <LinkStyles
        href={to}
        colorKind={colorKind}
        linkKind={linkKind}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      />
    );
  }
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  linkKind: PropTypes.oneOf(['default', 'no-underline']),
  colorKind: PropTypes.oneOf(['text', 'light', 'dark']),
};

Link.defaultProps = {
  linkKind: 'default',
  colorKind: 'text',
};

export default Link;
