// Naming convention → https://material.io/tools/color/

const color = {
  white: '#fff',
  black: '#000',
  grey900: '#16161d', // main
  grey50: '#f0f0f0',

  // Red shades
  red500: '#f74b59', // main

  // Blue-Grey shades
  blueGrey600: '#646d75', // main
  blueGrey100: '#cfd8dc',
  blueGrey50: '#f5f6f8',

  // Earth shades
  amber900: '#ff6f00',
  amber200: '#ffe49f',

  // Transparents
  whiteTransparent15: 'rgba(255, 255, 255, 0.15)',
  whiteTransparent60: 'rgba(255, 255, 255, 0.6)',
  grey900Transparent60: 'rgba(22, 22, 29, 0.6)',
  grey900Transparent15: 'rgba(22, 22, 29, 0.15)',
};

const theme = {
  name: 'light',

  scaffolding: {
    color: color.grey900,
    background: color.white,
    selection: color.grey900,
    selectionColor: color.white,
  },

  heyPolls: {
    brand: '#50c0ff',
    buttonHover: '#46a5db',
    border: '#e5eaee',
    footer: '#f5f7f9',
    outlineActive: 'rgba(80, 192, 255, 0.4)',
    outlineFocus: 'rgba(201, 210, 219, 0.4)',
  },

  about: {
    tileBackground: color.amber200,
    circleOne: '#4cc4fc',
    circleTwo: '#ff9cd6',
  },

  work: {
    scrumSpace: '#270152',
    hltCorp: '#edfbfe',
    virtualCooperation: '#f0f0f0',
    panoram: '#3f0969',
    rivieraTour: 'linear-gradient(to top left, #00acee, #011a76)',
    echo: '#03021a',
    skGaming: '#000',
    rDesktop: '#2f7aff',
    iten: '#f2f6f9',
    kiron: '#9debd9',
  },

  font: {
    inter: 'Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    size: {
      default: 16,
      medium: 18,
      large: 28,
    },
    color: {
      text: color.grey900,
      neutral: color.blueGrey600,
      lightTransparent: color.whiteTransparent60,
      darkTransparent: color.grey900Transparent60,
      brand: color.red500,
      light: color.white,
      dark: color.grey900,
    },
    heading: {
      h1: {
        fontSize: 36,
        fontSizeSmall: 32,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: 28,
        fontSizeSmall: 24,
        lineHeight: 1.6,
      },
      h3: {
        fontSize: 24,
        fontSizeSmall: 20,
        lineHeight: 1.6,
      },
      h4: {
        fontSize: 18,
        fontSizeSmall: 18,
        lineHeight: 1.6,
      },
      h5: {
        fontSize: 16,
        fontSizeSmall: 16,
        lineHeight: 1.6,
      },
      h6: {
        fontSize: 14,
        fontSizeSmall: 14,
        lineHeight: 1.6,
      },
    },
  },

  grid: {
    breakpoint: {
      // Phones
      toPhoneSmall: '@media screen and (max-width: 23.43625em)', // <374.98px
      fromPhoneSmall: '@media screen and (min-width: 23.4375em)', // >375px

      fromPhoneMedium: '@media screen and (min-width: 36em)', // > 576px
      phoneMedium: '@media screen and (min-width: 36em) and (max-width: 47.99875em)', // 576px, 767.98px
      toPhoneMedium: '@media screen and (max-width: 35.99875em)', // < 575.98px

      phones: '@media screen and (max-width: 47.99875em)', // 767.98px

      // Tablets
      fromTablet: '@media screen and (min-width: 48em)', // 768px
      tablets: '@media screen and (min-width: 48em) and (max-width: 61.87375em)', // 768px, 989.98px
      toTablet: '@media screen and (max-width: 61.87375em)', // 989.98px

      // Desktops
      fromDesktop: '@media screen and (min-width: 61.875em)', // 990px

      // Storytelling section
      min730: '@media screen and (min-width: 45.625em) and (min-height: 37.5em)', // > 730px width, 600px height
    },

    container: {
      default: '960px',
      narrow: '600px',
    },
  },

  color,
};

export default theme;
