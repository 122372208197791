/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { mb } from '../../utils/classes';
import { TextAccent, List, ListItem, Link, HeyLikeyLogo } from '../';
import { Wrapper, Bio, BottomNavigation, MetaFooter } from './Footer.style';

/**
 * Footer component
 */
const Footer = ({ showBio, colorKind }) => {
  const data = useStaticQuery(
    graphql`
      query {
        anface: file(relativePath: { eq: "an-face.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  );

  const randomMessage = () => {
    const getDay = () => {
      const daylist = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const day = daylist[new Date().getDay()];

      return day;
    };
    const randomNumber = () => Math.floor(Math.random() * Math.floor(4));

    const messages = [
      'Remember to drink water.',
      'Remember to smile.',
      'Thank you for scrolling this far.',
      `Enjoy the rest of your ${getDay()}.`,
    ];

    return messages[randomNumber()];
  };

  const defineMetaColorKind = () =>
    colorKind === 'dark' ? 'darkTransparent' : colorKind === 'light' ? 'lightTransparent' : 'neutral';

  return (
    <Wrapper colorKind={colorKind}>
      {showBio && (
        <Bio>
          <Img
            fixed={data.anface.childImageSharp.fixed}
            loading="eager"
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Image of Danijel Grabez"
            className="bio-anface"
          />
          <div className="bio-col-1">
            <p>
              <strong>Contact</strong> — People call me when they need an experienced and reliable person with a
              flexible approach to problem-solving. Primarily, I specialise in web and mobile products.
            </p>
          </div>
          <div className="bio-col-2">
            <p>
              If you think we can be a good fit, feel free to reach me via email{' '}
              <Link to="mailto:danijel.grabez@gmail.com?subject=Let’s%20work%20together">danijel.grabez@gmail.com</Link>
              .
            </p>
            <p css={mb(0)}>
              For social insights, you can reach me on <Link to="https://github.com/danijelgrabez">GitHub</Link>,{' '}
              <Link to="https://twitter.com/danijel_grabez">Twitter</Link>, or{' '}
              <Link to="https://www.linkedin.com/in/danijelgrabez">LinkedIn</Link>.
            </p>
          </div>
        </Bio>
      )}
      <BottomNavigation>
        <List listKind="unstyled">
          <ListItem>
            <strong>This website</strong> — <Link to="/">home</Link>, <Link to="/about">about</Link>,{' '}
            <Link to="/work">work</Link> and <Link to="/blog">blog</Link>
          </ListItem>
          <ListItem>
            <strong>More</strong> — Hey, Polls! <Link to="https://heypolls.com/">web</Link> and{' '}
            <Link to="https://play.google.com/store/apps/details?id=app.heypolls">Android app</Link>
          </ListItem>
          <ListItem>
            <strong>Even more</strong> —{' '}
            <Link to="https://heylikey.com/">
              <HeyLikeyLogo colorKind={colorKind}>HeyLikey</HeyLikeyLogo>
            </Link>
          </ListItem>
        </List>
      </BottomNavigation>
      <MetaFooter>
        <TextAccent colorKind={defineMetaColorKind()}>
          &copy; {new Date().getFullYear()}. All rights reserved.
        </TextAccent>
        <TextAccent colorKind={defineMetaColorKind()}>{randomMessage()}</TextAccent>
      </MetaFooter>
    </Wrapper>
  );
};

Footer.propTypes = {
  showBio: PropTypes.bool,
  colorKind: PropTypes.oneOf(['text', 'light', 'dark']),
};

Footer.defaultProps = {
  showBio: true,
  colorKind: 'text',
};

export default Footer;
