import React from 'react';
import PropTypes from 'prop-types';

/**
 * Rss icon component
 */
const Rss = ({ fill, size, ...props }) => (
  <svg width={size} height={size} fill={fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <circle cx="4.7" cy="27.3" r="4.7" />
      <path
        d="M12.5,13.5L12.5,13.5c-2.3-1.3-4.9-2.1-7.8-2.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c3,0,5.8,1.2,7.8,3.2
		c2,2,3.3,4.8,3.3,7.8c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C20.5,21.4,17.3,16.2,12.5,13.5z"
      />
      <path
        d="M12.5,1.1L12.5,1.1C10,0.4,7.4,0,4.7,0C3.4,0,2.4,1.1,2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c2.7,0,5.3,0.5,7.8,1.4
		c8.6,3.2,14.7,11.4,14.7,21.1c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C32,14.9,23.8,4.5,12.5,1.1z"
      />
    </g>
  </svg>
);

Rss.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

Rss.defaultProps = {
  fill: 'currentColor',
  size: 12,
};

export default Rss;
