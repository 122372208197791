import { css } from '@emotion/core';
import styled from '@emotion/styled';

const sectionStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.fromTablet} {
    display: grid;
    grid-template-columns: 20% minmax(0, 1fr);
    grid-gap: 0 30px;
  }
  ${breakpoint.fromDesktop} {
    grid-template-columns: 33.3333% minmax(0, 1fr);
  }
`;

/**
 * Component style declarations
 */
export const Section = styled('section')(sectionStyles);
