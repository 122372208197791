import { css } from '@emotion/core';
import { rem } from '../../utils/utils';

export const baseStyles = ({ withSpacing }) =>
  css`
    margin: ${withSpacing ? `0 0 ${rem(25)}` : 0};
  `;

export const sizeStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
  size,
}) =>
  size &&
  css`
    font-size: ${rem(font.size[size])};
    ${size === 'large'
      ? `
      ${breakpoint.toTablet} {
        font-size: ${rem(font.size[size] - 4)};
      }
    `
      : null};
  `;

export const weightStyles = ({ weight }) =>
  weight &&
  css`
    font-weight: ${weight};
  `;

export const colorStyles = ({ colorKind, theme: { font } }) =>
  colorKind &&
  css`
    color: ${font.color[colorKind]};
  `;
