import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Container } from '../../components';
import { getLinkColorStyles } from '../../components/Link/Link.style';

const defaultThemeConfig = {
  color: 'text',
  background: '#fff',
};

const wrapperStyles = ({ blogThemeConfig = defaultThemeConfig, theme: { font, color } }) => css`
  color: ${font.color[blogThemeConfig.color]};
  background: ${blogThemeConfig.background};
  min-height: 100vh;
  ${blogThemeConfig.background.includes('gradient') ? `filter: saturate(150%)` : null};

  hr {
    border: none;
    border-top: 2px solid ${blogThemeConfig.color === 'light' ? color.whiteTransparent15 : color.grey900Transparent15};
    margin: 0 0 30px;
  }
`;

const wrapperOverrideStyles = ({ blogThemeConfig }) =>
  blogThemeConfig.color !== 'text' ||
  (blogThemeConfig.background !== '#fff' &&
    css`
      article {
        a {
          transition: 0.15s all ease-in-out;
          ${getLinkColorStyles(blogThemeConfig.color)};
        }
      }
    `);

const blogContainerStyles = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const footerWrapperStyles = css`
  margin-top: auto;
`;

const blogPostFooterStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0 30px;
  margin-top: 50px;
  ${breakpoint.fromPhoneMedium} {
    grid-template-rows: 1fr;
    grid-template-columns: 180px 1fr 50px;
    grid-gap: 0 30px;
  }
`;

const quoteStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  color: ${color.grey900};
  background: ${color.white};
  font-family: monospace;
  padding: 20px;
  ${breakpoint.fromPhoneMedium} {
    padding: 50px;
  }
`;

const haikuStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: inline;
  ${breakpoint.fromPhoneMedium} {
    display: block;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const BlogContainer = styled(Container)(blogContainerStyles);
export const Article = styled('article')(wrapperOverrideStyles);
export const BlogPostFooter = styled('div')(blogPostFooterStyles);
export const FooterWrapper = styled('div')(footerWrapperStyles);
export const Quote = styled('p')(quoteStyles);
export const HaikuLine = styled('span')(haikuStyles);
