import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from '../../utils/utils';

const wrapperStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
}) => css`
  font-size: ${rem(font.size.medium)};
  font-weight: 700;
  color: ${font.color.brand};
  text-decoration: none;
  text-align: center;
  letter-spacing: -0.011em;
  white-space: nowrap;
  line-height: 1;
  display: inline-block;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  position: relative;
  background: none;
  padding: 0;
  ${breakpoint.toTablet} {
    padding: 5px;
    margin-left: -5px;
  }
  &:active {
    color: ${font.color.neutral};
  }

  svg {
    fill: currentColor;
  }
`;

const circleStyles = ({ isExternal, theme: { color } }) => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
  transition: 0.15s transform ease-in-out, 0.15s margin ease-in-out;
  box-shadow: 0 0 10px ${color.grey900Transparent15};
  background: ${color.white};

  button:hover & {
    transform: scale(1.2) ${isExternal ? `rotate(-45deg)` : null};
    margin-right: 10px;
  }

  button:active & {
    transform: scale(1.2) ${isExternal ? `translate(2px, -2px) rotate(-45deg)` : 'translateX(2px)'};
  }
`;

/**
 * Component style declarations
 */
export const ButtonWrapper = styled('button')(wrapperStyles);
export const Circle = styled('span')(circleStyles);
