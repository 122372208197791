import { css, keyframes } from '@emotion/core';
import theme from './theme';
import { rem } from './utils';

const fadeInUpKeyframes = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}
to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`;

export const fadeInUp150 = css`
  opacity: 0;
  animation: 0.15s ${fadeInUpKeyframes} cubic-bezier(0.77, 0, 0.175, 1) 0.4s;
  animation-fill-mode: forwards;
`;

const {
  font,
  font: { size },
  grid: { breakpoint },
} = theme;

// Display
export const dib = css`
  display: inline-block;
`;

// Typography
export const fontColor = kind => css`
  color: ${font.color[kind]};
`;

export const fontWeight = kind => css`
  font-weight: ${kind};
`;

export const fontSizeLead = css`
  font-size: ${rem(size.large)};
  ${breakpoint.tablets} {
    font-size: ${rem(size.large - 4)};
  }
  ${breakpoint.phones} {
    font-size: ${rem(size.medium + 4)};
  }
`;

export const tdu = css`
  text-decoration: underline;
`;

// Margins
const margin = direction => value => css`
  ${`margin-${direction}: ${value}px`}
`;

export const mt = margin('top');
export const mr = margin('right');
export const mb = margin('bottom');
export const ml = margin('left');

const responsiveMargin = direction => screenSizes => values => {
  const arr = [];

  screenSizes.forEach((screenSize, index) => {
    arr.push(`
      ${`
      ${breakpoint[screenSize]} {
        margin-${direction}: ${values[index]}px
      }`}
    `);
  });

  return arr
    .toString()
    .split(',')
    .join(' ');
};

export const respMT = responsiveMargin('top');
export const respMR = responsiveMargin('right');
export const respMB = responsiveMargin('bottom');
export const respML = responsiveMargin('left');
