/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './HeyLikeyLogo.style';

/**
 * HeyLikeyLogo component
 */
const HeyLikeyLogo = ({ children, colorKind }) => {
  return (
    <Wrapper colorKind={colorKind}>
      <svg width="67" height="15" viewBox="0 0 67 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="67" height="15" fill="none" />
        <path
          d="M10 5C10 4.34339 9.87067 3.69321 9.6194 3.08658C9.36812 2.47995 8.99983 1.92876 8.53553 1.46447C8.07124 1.00017 7.52004 0.631876 6.91342 0.380602C6.30679 0.129329 5.65661 -2.87013e-08 5 0C4.34339 2.87013e-08 3.69321 0.129329 3.08658 0.380602C2.47995 0.631876 1.92876 1.00017 1.46447 1.46447C1.00017 1.92876 0.631876 2.47996 0.380602 3.08658C0.129329 3.69321 -5.74026e-08 4.34339 0 5L10 5Z"
          fill="black"
        />
        <path
          d="M0 5C-5.74026e-08 5.65661 0.129329 6.30679 0.380602 6.91342C0.631876 7.52005 1.00017 8.07124 1.46447 8.53553C1.92876 8.99983 2.47995 9.36812 3.08658 9.6194C3.69321 9.87067 4.34339 10 5 10C5.65661 10 6.30679 9.87067 6.91342 9.6194C7.52004 9.36812 8.07124 8.99983 8.53553 8.53553C8.99983 8.07124 9.36812 7.52005 9.6194 6.91342C9.87067 6.30679 10 5.65661 10 5L0 5Z"
          fill="black"
        />
        <path
          d="M41.512 5.66473C41.512 6.98739 41.3307 8.19273 40.968 9.28073C40.6267 10.3687 40.104 11.3074 39.4 12.0967C38.696 12.8647 37.832 13.4621 36.808 13.8887C35.784 14.2941 34.6 14.4967 33.256 14.4967C31.8693 14.4967 30.6533 14.2834 29.608 13.8567C28.584 13.4301 27.7307 12.8327 27.048 12.0647C26.3653 11.2754 25.8533 10.3474 25.512 9.28073C25.1707 8.19273 25 6.98739 25 5.66473H27.976C27.976 7.00873 28.1573 8.17139 28.52 9.15273C28.904 10.1341 29.4907 10.8914 30.28 11.4247C31.0693 11.9581 32.0613 12.2247 33.256 12.2247C34.472 12.2247 35.464 11.9581 36.232 11.4247C37.0213 10.8914 37.5973 10.1341 37.96 9.15273C38.344 8.17139 38.536 7.00873 38.536 5.66473H41.512Z"
          fill="black"
        />
        <path
          d="M61.3932 10C60.4766 10 59.6395 9.77652 58.8819 9.32955C58.1243 8.875 57.5183 8.26894 57.0637 7.51136C56.6167 6.75379 56.3932 5.91667 56.3932 5C56.3932 4.07576 56.6167 3.23864 57.0637 2.48864C57.5183 1.73106 58.1243 1.12879 58.8819 0.681818C59.6395 0.227273 60.4766 0 61.3932 0C62.3175 0 63.1546 0.227273 63.9046 0.681818C64.6622 1.12879 65.2645 1.73106 65.7114 2.48864C66.166 3.23864 66.3932 4.07576 66.3932 5C66.3932 5.91667 66.166 6.75379 65.7114 7.51136C65.2645 8.26894 64.6622 8.875 63.9046 9.32955C63.1546 9.77652 62.3175 10 61.3932 10Z"
          fill="black"
        />
      </svg>

      {children}
    </Wrapper>
  );
};

HeyLikeyLogo.defaultProps = {
  children: null,
  colorKind: PropTypes.oneOf(['text', 'light', 'dark']),
};

HeyLikeyLogo.propTypes = {
  children: PropTypes.node,
  colorKind: 'text',
};

export default HeyLikeyLogo;
/* eslint-enable max-len */
