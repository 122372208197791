import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from '../../utils/utils';

export const listStyles = ({ theme: { font } }) => css`
  font-size: ${rem(font.size.medium)};
  margin: 0;
  padding: 0;
`;

export const kindStyles = ({ listKind }) => {
  switch (listKind) {
    case 'unstyled':
      return css`
        list-style-type: none;
      `;
    case 'emDash':
      return css`
        list-style-type: none;
        line-height: 2.3;

        li {
          &::before {
            content: '— ';
          }
        }
      `;
    case 'default':
      return css``;
  }
};

export const itemStyles = css``;

/**
 * Component style declaration
 */
export const ListStyles = styled('ul')(listStyles, kindStyles);
export const ListItemStyles = styled('li')(itemStyles);
