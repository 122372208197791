import React from 'react';
import PropTypes from 'prop-types';

/**
 * ArrowRegularWeight icon component
 */
const ArrowRegularWeight = ({ fill, size, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.23923 8.52137L11.7343 8.51233L7.6755 12.5531L8.66083 13.5384L14.392 7.80724L8.66987 2.0851L7.68454 3.05234L11.7343 7.10214L1.23922 7.11118L1.23923 8.52137Z"
      fill={fill}
    />
  </svg>
);

ArrowRegularWeight.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

ArrowRegularWeight.defaultProps = {
  fill: 'currentColor',
  size: 15,
};

export default ArrowRegularWeight;
