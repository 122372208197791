import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const wrapperStyles = ({
  colorKind,
  theme: {
    font: { color },
  },
}) =>
  css`
    &:hover {
      path:first-of-type {
        opacity: 0;
      }
    }

    svg {
      transform: translateY(3px);
      width: 48px;
      padding-right: 4px;
    }
    path {
      fill: ${colorKind === 'light' ? color.light : color.dark};
    }
  `;

/**
 * Component style declaration
 */
export const Wrapper = styled('span')(wrapperStyles);
