import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Link from '../Link';
import TextAccent from '../TextAccent';

const wrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  line-height: 1;
  padding-top: 27px;
  ${breakpoint.fromDesktop} {
    padding-top: 47px;
  }
`;

const linkStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  line-height: 1;
  text-decoration: none;
  position: relative;
  ${breakpoint.fromDesktop} {
    margin-left: 0;
  }

  svg {
    margin-left: -5px;
  }

  strong {
    margin-left: 3px;
  }
`;

const titleStyles = css`
  &:before {
    content: ' · ';
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('nav')(wrapperStyles);
export const LinkBack = styled(Link)(linkStyles);
export const Title = styled(TextAccent)(titleStyles);
