import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { isValidProp } from '../../utils/utils';
import { baseStyles, sizeStyles, weightStyles, colorStyles } from './TextAccent.style';

/**
 * TextAccent component
 */
const TextAccent = styled(({ element, children, ...props }) => React.createElement(`${element}`, props, children), {
  shouldForwardProp: isValidProp,
})(baseStyles, sizeStyles, weightStyles, colorStyles);

TextAccent.propTypes = {
  element: PropTypes.string,
  size: PropTypes.oneOf(['default', 'medium', 'large']),
  weight: PropTypes.oneOf([400, 500, 700]),
  colorKind: PropTypes.oneOf(['text', 'neutral', 'light', 'lightTransparent', 'dark', 'darkTransparent', 'brand']),
  withSpacing: PropTypes.bool,
};

TextAccent.defaultProps = {
  element: 'p',
  weight: 400,
  size: 'medium',
  colorKind: 'text',
  withSpacing: false,
};

export default TextAccent;
