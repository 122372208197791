import { css } from '@emotion/core';
import styled from '@emotion/styled';

const wrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding-bottom: 20px;
  ${breakpoint.fromDesktop} {
    padding-bottom: 50px;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
