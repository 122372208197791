import styled from '@emotion/styled';
import { css } from '@emotion/core';

const tallOuterContainerStyles = ({ dynamicHeight }) =>
  css`
    height: ${dynamicHeight}px;
    position: relative;
    width: 100%;
  `;

const stickyInnerContainerStyles = css`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: white;
`;

const horizontalTranslateContainerStyles = ({ translateX }) =>
  css`
    transform: translate3d(${translateX}px, 0, 0);
    position: absolute;
    height: 100%;
    will-change: transform;
    transform-style: preserve-3d;
  `;

/**
 * Component style definitions
 */
export const TallOuterContainer = styled('div')(tallOuterContainerStyles);
export const StickyInnerContainer = styled('div')(stickyInnerContainerStyles);
export const HorizontalTranslateContainer = styled('div')(horizontalTranslateContainerStyles);
