import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../utils/utils';
import { getLinkColorStyles } from '../Link/Link.style';

const baseStyles = ({ colorKind, theme: { color, font } }) =>
  css`
    border-top: 2px solid ${color.grey900Transparent15};
    padding-top: 50px;
    margin-top: 50px;
    font-size: ${rem(font.size.medium)};
    color: ${font.color[colorKind]};
  `;

/**
 * Override styles introduced for pages which have different background color
 * (e.g. blog posts)
 **/
const baseOverrideStyles = ({ colorKind, theme: { color } }) =>
  colorKind !== 'text' &&
  css`
    border-top: 2px solid ${colorKind === 'light' ? color.whiteTransparent15 : color.grey900Transparent15};

    a {
      ${getLinkColorStyles(colorKind)};
    }
  `;

const bottomNavigationStyles = css`
  margin-bottom: 35px;
`;

const metaFooterStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  justify-content: space-between;
  padding-bottom: 40px;
  ${breakpoint.fromTablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;

    p:nth-of-type(2) {
      text-align: right;
    }
  }
`;

const bioStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-gap: 0;
  margin-bottom: 65px;
  ${breakpoint.fromPhoneMedium} {
    grid-template-columns: 150px 1fr;
    grid-gap: 0 30px;
  }
  ${breakpoint.fromTablet} {
    grid-gap: 30px;
    margin-bottom: 140px;
    grid-template-columns: 20% 1fr 1fr;
  }
  ${breakpoint.fromDesktop} {
    grid-template-columns: 33.3333% 1fr 1fr;
  }

  .bio-anface {
    border-radius: 50%;
    justify-self: flex-end;
    margin: 0 auto 25px;
    ${breakpoint.fromTablet} {
      margin: 0;
    }
    ${breakpoint.phoneMedium} {
      grid-row: span 2;
    }
  }

  .bio-col-1 {
    ${breakpoint.phoneMedium} {
      grid-column: 2;
    }
  }

  .bio-col-2 {
    ${breakpoint.phoneMedium} {
      grid-column: 2;
    }
  }
`;

/**
 * Component style declaration
 */
export const Wrapper = styled('footer')(baseStyles, baseOverrideStyles);
export const Bio = styled('div')(bioStyles);
export const BottomNavigation = styled('nav')(bottomNavigationStyles);
export const MetaFooter = styled('div')(metaFooterStyles);
