import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { isValidProp } from '../../utils/utils';
import { baseStyles, kindStyles, sizeStyles, weightStyles } from './Heading.style';

/**
 * Heading component
 */
const Heading = styled(({ element, children, ...props }) => React.createElement(`${element}`, props, children), {
  shouldForwardProp: isValidProp,
})(baseStyles, kindStyles, sizeStyles, weightStyles);

Heading.propTypes = {
  children: PropTypes.node,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  colorKind: PropTypes.oneOf(['text', 'neutral', 'light', 'lightTransparent', 'dark', 'darkTransparent', 'brand']),
  weight: PropTypes.oneOf([400, 500, 700]),
};

Heading.defaultProps = {
  children: null,
  colorKind: 'text',
  weight: 700,
};

export default Heading;
