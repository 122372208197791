import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({
  theme: {
    grid: { container, breakpoint },
  },
}) => css`
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  ${breakpoint.tablets} {
    max-width: calc(100% - 30px);
    padding: 0;
  }
  ${breakpoint.fromDesktop} {
    width: ${container.default};
    padding: 0;
  }
`;

/**
 * Component style declarations
 */
export const ContainerStyles = styled('div')(baseStyles);
