/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * SEO component
 */
const SEO = ({ lang, title, description, image, pagePath, meta }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            siteUrl
            description
            image
            social {
              twitter
            }
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image || site.siteMetadata.image;
  const metaURL = pagePath ? `${site.siteMetadata.siteUrl}${pagePath}` : site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={title}
      meta={[
        { name: `author`, content: site.siteMetadata.author },
        {
          name: `description`,
          content: metaDescription,
        },
        { name: `apple-mobile-web-app-status-bar-style`, content: `black` },
        {
          name: `image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:secure_url`,
          content: metaImage,
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 600,
        },
        {
          property: `og:image:alt`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaURL,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: `site.siteMetadata.social.twitter`,
        },
        {
          name: `twitter:url`,
          content: metaURL,
        },
        {
          name: `twitter:image:src`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
  pagePath: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pagePath: PropTypes.string,
};

export default SEO;
